<template>
  <Page full>
    <Grid
      type="dimension"
      title="Dimensions"
      :multiple="true"
      :permanent-filters="{
        company_id: $store.state.session.company.company_id
      }"
      :isMain="true"
    />
  </Page>
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import PageMixin from '@/components/mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'DimensionsPage',
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
